import React from 'react';

import closeIcon from '../../assets/close-black.svg';
import styles from './custom-dialog.module.scss';

interface ICustomDialogProps {
  children: React.ReactNode;
  show: boolean;
  onClose: () => void;
}

export const CustomDialog: React.FC<ICustomDialogProps> = ({
  children,
  show,
  onClose,
}) => {
  const blockOutRef = React.useRef<HTMLDivElement>(null);

  const escapeListener = React.useCallback(
    (e: any) => {
      if (e.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );
  const clickListener = React.useCallback(
    (e: any) => {
      if (
        e.target.className &&
        blockOutRef.current &&
        e.target.className === blockOutRef.current.className
      ) {
        onClose();
      }
    },
    [blockOutRef, onClose],
  );

  React.useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);
    return () => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, [clickListener, escapeListener]);

  React.useEffect(() => {
    show
      ? document
          .querySelector<HTMLElement>('body')
          ?.setAttribute('style', 'overflow: hidden')
      : document
          .querySelector<HTMLElement>('body')
          ?.setAttribute('style', 'overflow: auto');
  }, [show]);

  return (
    <>
      <div
        ref={blockOutRef}
        className={show ? styles.blockOut : styles.blockOutHidden}
      ></div>

      <div className={show ? styles.content : styles.contentHidden}>
        {/*<button onClick={onClose} className={styles.closeBtn}>*/}
        {/*  <img src={closeIcon} alt={'close'} />*/}
        {/*</button>*/}

        {children}
      </div>
    </>
  );
};
