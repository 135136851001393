import {
  ArrayInput,
  required,
  SaveButton,
  SimpleFormIterator,
  TextInput,
  useRedirect,
  Toolbar,
  ImageInput,
  DateInput,
  SelectInput,
  TabbedForm,
  FormTab,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import moment from 'moment';

import {
  getStaticPagePayload,
  IStaticPagePayload,
  staticPageService,
} from '../../services/static-pages';
import { PAGES, requiredMessage } from '../../helpers/consts';
import { getLocales } from '../../helpers/locales';
import { validateImage } from '../../helpers/validate';
import { PersonImage } from '../../components/person-image';

const StaticPageCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton label="Update" alwaysEnable />
  </Toolbar>
);

export const StaticPageForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [mdData, setMdData] = React.useState<any>({});

  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [locales, setLocales] = React.useState<string[]>([]);
  const [staticPage, setStaticPage] = React.useState<IStaticPagePayload | null>(
    null,
  );

  const onSubmit = async (data: any) => {
    let seoKeywords = data.seo_keywords || {};
    if (Array.isArray(seoKeywords)) {
      seoKeywords = {};
    }

    const staticPagePayload = await getStaticPagePayload(
      { ...data, seo_keywords: seoKeywords },
      mdData,
    );

    if (id) {
      await staticPageService.updateOne(id, staticPagePayload, {
        success: () => redirect('/static-pages'),
        failure: () => console.error('not updated'),
      });
    } else {
      await staticPageService.createOne(staticPagePayload, {
        success: () => redirect('/static-pages'),
        failure: () => console.error('not created'),
      });
    }
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      staticPageService.getOne(id).then((res) => setStaticPage(res?.data));
    }
  }, [id]);

  React.useEffect(() => {
    if (staticPage) {
      const newObj: any = {};
      Object.entries(staticPage.description).forEach((item) => {
        newObj[`description.${item[0]}`] = item[1];
      });

      setMdData(newObj);
    }
  }, [staticPage]);

  if (id && !staticPage) {
    // TODO: add loader
    return null;
  }

  return (
    <TabbedForm
      onSubmit={onSubmit}
      toolbar={id ? <StaticPageCreateToolbar /> : undefined}
      shouldFocusError
    >
      <FormTab label={'General'}>
        <SelectInput
          isRequired
          fullWidth
          defaultValue={staticPage?.name}
          validate={required(requiredMessage)}
          name={'name'}
          source={'name'}
          choices={PAGES}
        />

        {staticPage?.name === 'reward-up' && (
          <ArrayInput
            name={'Information Items'}
            source={'information_list'}
            defaultValue={staticPage?.information_list}
          >
            <SimpleFormIterator>
              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`item.${locale}`}
                  label={`Item (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}
            </SimpleFormIterator>
          </ArrayInput>
        )}

        {staticPage?.name === 'donate' && (
          <ArrayInput
            name={'Donate Items'}
            source={'donate_list'}
            defaultValue={staticPage?.donate_list}
          >
            <SimpleFormIterator>
              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`item.${locale}`}
                  label={`Item (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}
            </SimpleFormIterator>
          </ArrayInput>
        )}

        {staticPage?.name === 'about' && (
          <ArrayInput
            name={'Founders'}
            source={'founders'}
            defaultValue={staticPage?.founders}
          >
            <SimpleFormIterator>
              <ImageInput
                source="avatar"
                label="Avatar"
                validate={(value) =>
                  validateImage(value, (errorMessage) => errorMessage)
                }
              >
                <PersonImage />
              </ImageInput>

              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`name.${locale}`}
                  label={`Name (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}

              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`description.${locale}`}
                  label={`Description (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}
            </SimpleFormIterator>
          </ArrayInput>
        )}

        {staticPage?.name === 'home' && (
          <ArrayInput
            label={'Persons'}
            source="persons"
            defaultValue={staticPage?.persons || []}
          >
            <SimpleFormIterator>
              <ImageInput
                source="avatar"
                label="Avatar"
                validate={(value) =>
                  validateImage(value, (errorMessage) => errorMessage)
                }
              >
                <PersonImage />
              </ImageInput>

              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`name.${locale}`}
                  label={`Name (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}

              <DateInput
                fullWidth
                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                source="date"
                label="Date"
                validate={required(requiredMessage)}
              />

              {locales?.map((locale) => (
                <TextInput
                  multiline
                  fullWidth
                  key={locale}
                  source={`quote.${locale}`}
                  label={`Quote (${locale})`}
                  validate={
                    locale === defaultLocale
                      ? required(requiredMessage)
                      : undefined
                  }
                />
              ))}
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </FormTab>

      {locales?.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            label={`Title (${locale})`}
            name={`title.${locale}`}
            source={`title.${locale}`}
          />

          {staticPage?.name === 'reward-up' && (
            <TextInput
              multiline
              fullWidth
              label={`Information List Title (${locale})`}
              name={`information_list_title.${locale}`}
              source={`information_list_title.${locale}`}
            />
          )}

          {staticPage?.name === 'donate' && (
            <TextInput
              multiline
              fullWidth
              label={`Donate List Title (${locale})`}
              name={`donate_list_title.${locale}`}
              source={`donate_list_title.${locale}`}
            />
          )}

          <div className={'form-item'}>
            <label>Description({locale})</label>

            <MDEditor
              height={200}
              value={mdData[`description.${locale}`]}
              onChange={(val) =>
                setMdData((prev: any) => {
                  const newValue = {
                    [`description.${locale}`]: val,
                  };
                  return {
                    ...prev,
                    ...newValue,
                  };
                })
              }
            />
          </div>

          <TextInput
            multiline
            fullWidth
            label={`SEO Title (${locale})`}
            name={`seo_title.${locale}`}
            source={`seo_title.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`SEO Description (${locale})`}
            name={`seo_description.${locale}`}
            source={`seo_description.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`SEO Keywords (${locale})`}
            name={`seo_keywords.${locale}`}
            source={`seo_keywords.${locale}`}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
};
