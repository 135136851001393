import { IDoneFunctions } from '../interfaces';
import { instances } from '../core/axios';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface IAccountPayload {
  amount: number;
}

export const accountsService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`account/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: IAccountPayload, done?: IDoneFunctions) {
    const {
      data: { _id },
    } = await instances.commonInstance.get('auth/check');

    const data = {
      ...payload,
      user_id: _id,
    };

    try {
      await instances.commonInstance.post('account', data);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`account`, data, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/account`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: IAccountPayload, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.patch(`account/${id}`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`account/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/account`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
