import { IDoneFunctions } from '../interfaces';
import { instance } from '../core/axios';

// TODO: types
export const filesService = {
  async createOne(payload: any, done?: IDoneFunctions) {
    const authorization = `Bearer ${localStorage.getItem('access_token')}`;

    const formData = new FormData();
    formData.append('file', payload.rawFile);

    try {
      const { data } = await instance.post('/uploaded-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data;',
          Authorization: authorization,
        },
      });

      return data;
    } catch (e: any) {
      if (e.response.status === 401) {
        const { data } = await instance.get('/auth/refresh');
        localStorage.setItem('access_token', data.access_token);

        const { data: file } = await instance.post(
          `/uploaded-files`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data;',
              Authorization: `Bearer ${data.access_token}`,
            },
          },
        );

        return file;
      }
    }
  },
};
