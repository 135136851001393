import { IDoneFunctions, ITranslatedField } from '../interfaces';
import { instances } from '../core/axios';
import { filesService } from './files';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface ICrimeLink {
  title: Record<string, string>;
  description: Record<string, string>;
  link: string;
  link_name: Record<string, string>;
  link_description: Record<string, string>;
  poster: { src: string; file_id: string };
}

export interface ICrime {
  name: ITranslatedField;
  description: ITranslatedField;
  poster: File | null;
  poster_author: string;
  links: ICrimeLink[];
}

export interface ICrimePayload {
  body: ICrime;
  poster: any;
}

export const crimesService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`crimes/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: ICrimePayload, done?: IDoneFunctions) {
    const links: ICrimeLink[] = [];

    if (payload.body?.links?.length) {
      for (const item of payload.body.links) {
        let poster: any = item.poster || {};

        if (poster.rawFile) {
          const file = await filesService.createOne(item.poster);
          poster = file;
        }

        links.push({
          title: item.title,
          description: item.description,
          link: item.link,
          link_name: item.link_name,
          link_description: item.link_description,
          poster: {
            src: poster.url || poster.src,
            file_id: poster._id || poster.file_id,
          },
        });
      }
    }

    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({ ...payload.body, links }, null, 2),
    );
    payload.poster && formData.append('poster', payload.poster);

    try {
      await instances.fileInstance.post('crimes', formData);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.fileInstance
              .post(`crimes`, formData, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/crimes`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: ICrimePayload, done?: IDoneFunctions) {
    const links: ICrimeLink[] = [];

    if (payload.body?.links?.length) {
      for (const item of payload.body.links) {
        let poster: any = item.poster || {};

        if (poster.rawFile) {
          const file = await filesService.createOne(item.poster);
          poster = file;
        }

        links.push({
          title: item.title,
          description: item.description,
          link: item.link,
          link_name: item.link_name,
          link_description: item.link_description,
          poster: {
            src: poster.url || poster.src,
            file_id: poster._id || poster.file_id,
          },
        });
      }
    }

    const formData = new FormData();

    if (payload.poster instanceof File) {
      formData.append(
        'data',
        JSON.stringify({ ...payload.body, links }, null, 2),
      );
      payload.poster && formData.append('poster', payload.poster);
    } else {
      formData.append(
        'data',
        JSON.stringify(
          { ...payload.body, poster: payload.poster, links },
          null,
          2,
        ),
      );
    }

    try {
      await instances.fileInstance.patch(`crimes/${id}`, formData);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.fileInstance
              .patch(`crimes/${id}`, formData, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/crimes`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
