import { useRecordContext } from 'react-admin';
import {
  IMAGE_ALLOWED_EXTENSIONS,
  IMAGE_BAD_EXTENSION,
  MAX_IMAGE_ERROR,
  MAX_IMAGE_SIZE,
} from '../helpers/consts';
import { CustomImageField } from './custom-image-field';
import * as React from 'react';

export const LinkPoster = (props: any) => {
  const record = useRecordContext();

  if (
    record?.rawFile?.type &&
    !IMAGE_ALLOWED_EXTENSIONS.includes(record?.rawFile?.type)
  ) {
    return (
      <div style={{ width: 200, marginTop: -15 }}>
        <div className={'error-image'}>{IMAGE_BAD_EXTENSION}</div>
      </div>
    );
  }

  if (record?.rawFile?.size > MAX_IMAGE_SIZE) {
    return (
      <div style={{ width: 130, height: 120 }}>
        <img
          src={record.src}
          style={{ width: 120, height: 120, objectFit: 'contain' }}
          alt={'poster'}
        />
        <div className={'error-image'}>{MAX_IMAGE_ERROR}</div>
      </div>
    );
  }

  return record.file_id ? (
    <CustomImageField name={'poster'} source="src" title="title" />
  ) : (
    record.src && (
      <img
        src={record.src}
        style={{ width: 120, height: 120, objectFit: 'contain' }}
        alt={'avatar'}
      />
    )
  );
};
