import React from 'react';
import ReactDOM from 'react-dom/client';
import { Admin, Resource } from 'react-admin';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { CrimeCreate, CrimeEdit } from './pages/crimes';
import { CrimesList } from './pages/crimes/list';
import { wrapperDataProvider } from './wrapper-data-provider';
import { fetchJson as httpClient } from './http-client';
import { PerpStatusCreate, PerpStatusEdit } from './pages/perp-statuses';
import { PerpCreate, PerpEdit } from './pages/perps';
import authProvider from './auth-provider';
import { LoginPage } from './pages/login-page';
import { PerpsList } from './pages/perps/list';
import { PerpStatusesList } from './pages/perp-statuses/list';
import { LanguagesList } from './pages/languages/list';
import { LanguageCreate, LanguageEdit } from './pages/languages';
import { FaqsList } from './pages/faqs/list';
import { FaqCreate, FaqEdit } from './pages/faqs';
import { AccountList } from './pages/accounts/list';
import { AccountCreate, AccountEdit } from './pages/accounts';
import { StaticPageCreate, StaticPageEdit } from './pages/static-pages';
import { AdminList } from './pages/admins/list';
import { AdminCreate, AdminEdit } from './pages/admins';
import { TransactionsList } from './pages/transactions/list';
import { TransactionCreate, TransactionEdit } from './pages/transactions';
import { DictionariesList } from './pages/dictionaries/list';
import { DictionaryCreate, DictionaryEdit } from './pages/dictionaries';
import { StaticPagesList } from './pages/static-pages/list';

import UsersIcon from '@mui/icons-material/Group';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TranslateIcon from '@mui/icons-material/Translate';
import PaidIcon from '@mui/icons-material/Paid';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';
import QuizIcon from '@mui/icons-material/Quiz';
import DescriptionIcon from '@mui/icons-material/Description';
import HowToRegIcon from '@mui/icons-material/HowToReg';

const dataProvider = wrapperDataProvider(
  process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  httpClient,
);

const StoreAdmin = () => (
  <Admin
    requireAuth
    basename="/admin"
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
  >
    <Resource
      name="account"
      options={{
        label: 'Account',
      }}
      icon={AccountBalanceWalletIcon}
      list={AccountList}
      edit={AccountEdit}
      create={AccountCreate}
    />

    <Resource
      name="admins"
      icon={UsersIcon}
      list={AdminList}
      create={AdminCreate}
      edit={AdminEdit}
    />

    <Resource
      name="dictionaries"
      icon={TranslateIcon}
      options={{ label: 'Dictionary' }}
      list={DictionariesList}
      create={DictionaryCreate}
      edit={DictionaryEdit}
    />

    <Resource
      name="crimes"
      list={CrimesList}
      create={CrimeCreate}
      edit={CrimeEdit}
    />

    <Resource
      name="transactions"
      icon={PaidIcon}
      list={TransactionsList}
      create={TransactionCreate}
      edit={TransactionEdit}
    />

    <Resource
      name="perps"
      icon={AccountBoxIcon}
      list={PerpsList}
      create={PerpCreate}
      edit={PerpEdit}
    />

    <Resource
      name="perp-statuses"
      icon={HowToRegIcon}
      options={{ label: 'Perp Statuses' }}
      list={PerpStatusesList}
      create={PerpStatusCreate}
      edit={PerpStatusEdit}
    />

    <Resource
      name="languages"
      icon={LanguageIcon}
      list={LanguagesList}
      create={LanguageCreate}
      edit={LanguageEdit}
    />

    <Resource
      name="faqs"
      icon={QuizIcon}
      list={FaqsList}
      create={FaqCreate}
      edit={FaqEdit}
    />

    {/*<Resource*/}
    {/*  name="founders"*/}
    {/*  list={FoundersList}*/}
    {/*  create={FounderCreate}*/}
    {/*  edit={FounderEdit}*/}
    {/*/>*/}

    <Resource
      name="static-pages"
      icon={DescriptionIcon}
      options={{ label: 'Static Pages' }}
      list={StaticPagesList}
      create={StaticPageCreate}
      edit={StaticPageEdit}
    />
  </Admin>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StoreAdmin />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
