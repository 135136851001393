import { IDoneFunctions, ITranslatedField } from '../interfaces';
import { instances } from '../core/axios';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface IFaqPayload {
  answer: ITranslatedField;
  question: ITranslatedField;
}

export const faqsService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`faqs/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: IFaqPayload, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.post('faqs', payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`faqs`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/faqs`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: IFaqPayload, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.patch(`faqs/${id}`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`faqs/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/faqs`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
