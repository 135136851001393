import {
  FormTab,
  required,
  SimpleForm,
  TabbedForm,
  TextInput,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import { requiredMessage } from '../../helpers/consts';
import {
  dictionariesService,
  IDictionaryPayload,
} from '../../services/dictionaries';
import { getLocales } from '../../helpers/locales';
import { CustomDialog } from '../../components/custom-dialog';

export const DictionaryForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [dictionary, setDictionary] = React.useState<IDictionaryPayload | null>(
    null,
  );
  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [locales, setLocales] = React.useState<string[]>([]);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = async (data: any) => {
    if (!id) {
      await dictionariesService.createOne(data, {
        success: () => redirect('/dictionaries'),
        failure: (error) => {
          setErrorMessage((error?.response?.data as any)?.message || '');
          setShowErrorModal(true);
        },
      });
    } else {
      await dictionariesService.updateOne(id, data, {
        success: () => redirect('/dictionaries'),
        failure: () => console.error('not updated'),
      });
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    setErrorMessage('');
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      dictionariesService.getOne(id).then((res) => setDictionary(res?.data));
    }
  }, [id]);

  if (id && !dictionary) {
    // TODO: add loader
    return null;
  }

  return (
    <TabbedForm onSubmit={onSubmit} shouldFocusError>
      <FormTab label={'General'}>
        <TextInput
          multiline
          fullWidth
          disabled={!!id}
          label={'Field Key'}
          source={`field_key`}
          validate={required(requiredMessage)}
          defaultValue={dictionary?.field_key || ''}
        />
      </FormTab>

      {locales.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            name={`value.${locale}`}
            source={`value.${locale}`}
          />
        </FormTab>
      ))}

      <CustomDialog show={showErrorModal} onClose={closeErrorModal}>
        <div className={'error-box'}>
          <ErrorIcon width={'90px'} height={'90px'} color={'error'} />

          <div className={'error'}>{errorMessage}</div>
        </div>
      </CustomDialog>
    </TabbedForm>
    // <SimpleForm onSubmit={onSubmit} shouldFocusError>
    //   <TextInput
    //     multiline
    //     fullWidth
    //     disabled={!!id}
    //     label={'Field Key'}
    //     source={`field_key`}
    //     validate={required(requiredMessage)}
    //     defaultValue={dictionary?.field_key || ''}
    //   />
    //
    //   {locales.map((locale) => (
    //     <TextInput
    //       multiline
    //       fullWidth
    //       key={locale}
    //       label={`Value (${locale})`}
    //       source={`value.${locale}`}
    //       isRequired={locale === defaultLocale}
    //       validate={
    //         locale === defaultLocale ? required(requiredMessage) : undefined
    //       }
    //       defaultValue={dictionary?.value[locale] || ''}
    //     />
    //   ))}
    //
    //   <CustomDialog show={showErrorModal} onClose={closeErrorModal}>
    //     <div className={'error-box'}>
    //       <ErrorIcon width={'90px'} height={'90px'} color={'error'} />
    //
    //       <div className={'error'}>{errorMessage}</div>
    //     </div>
    //   </CustomDialog>
    // </SimpleForm>
  );
};
