import {
  FormTab,
  required,
  TabbedForm,
  TextInput,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { requiredMessage } from '../../helpers/consts';
import { faqsService, IFaqPayload } from '../../services/faqs';
import { getLocales } from '../../helpers/locales';

export const FaqForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [faq, setFaq] = React.useState<IFaqPayload | null>(null);
  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [locales, setLocales] = React.useState<string[]>([]);

  const onSubmit = async (data: any) => {
    if (!id) {
      await faqsService.createOne(data, {
        success: () => redirect('/faqs'),
        failure: () => console.error('not created'),
      });
    } else {
      await faqsService.updateOne(id, data, {
        success: () => redirect('/faqs'),
        failure: () => console.error('not updated'),
      });
    }
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      faqsService.getOne(id).then((res) => setFaq(res?.data));
    }
  }, [id]);

  if (id && !faq) {
    // TODO: add loader
    return null;
  }

  return (
    <TabbedForm onSubmit={onSubmit} shouldFocusError>
      {locales.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`Question (${locale})`}
            source={`question.${locale}`}
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            defaultValue={faq?.question[locale] || ''}
          />

          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`Answer (${locale})`}
            source={`answer.${locale}`}
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            defaultValue={faq?.answer[locale] || ''}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
};
