import {
  required,
  SimpleForm,
  useRedirect,
  NumberInput,
  TextInput,
} from 'react-admin';
import moment from 'moment';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { requiredMessage } from '../../helpers/consts';
import {
  ITransactionPayload,
  transactionsService,
} from '../../services/transactions';

export const TransactionForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [transaction, setTransaction] =
    React.useState<ITransactionPayload | null>(null);

  const onSubmit = async (data: any) => {
    const payDate = moment(new Date()).format('DD.MM.YYYY HH:mm:ss');

    if (!id) {
      await transactionsService.createOne(
        { ...data, transaction_id: uuidv4(), pay_date: payDate },
        {
          success: () => redirect('/transactions'),
          failure: () => console.error('not created'),
        },
      );
    } else {
      await transactionsService.updateOne(
        id,
        { ...data, pay_date: payDate },
        {
          success: () => redirect('/transactions'),
          failure: () => console.error('not updated'),
        },
      );
    }
  };

  React.useEffect(() => {
    if (id) {
      transactionsService.getOne(id).then((res) => setTransaction(res?.data));
    }
  }, [id]);

  if (id && !transaction) {
    // TODO: add loader
    return null;
  }

  return (
    <SimpleForm onSubmit={onSubmit} shouldFocusError>
      {id && (
        <>
          <TextInput
            disabled
            fullWidth
            label={'Transaction id'}
            source={'transaction_id'}
            defaultValue={transaction?.transaction_id || 0}
          />
          <TextInput
            disabled
            fullWidth
            label={'Pay date'}
            source={'pay_date'}
            defaultValue={transaction?.pay_date || ''}
          />
        </>
      )}

      <NumberInput
        fullWidth
        source={'amount'}
        defaultValue={transaction?.amount || 0}
        validate={required(requiredMessage)}
      />
    </SimpleForm>
  );
};
