export const requiredMessage = 'This field is required';
export const DEFAULT_LOCALE = 'en';

export const MAX_IMAGE_SIZE = 25000000;
export const MAX_IMAGE_ERROR = 'Maximum image size 25 megabytes';

export const INVALID_URL_MESSAGE = 'Invalid url';

export const IMAGE_ALLOWED_EXTENSIONS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/svg+xml',
];
export const IMAGE_BAD_EXTENSION =
  'Available extensions for images are jpg, jpeg, png, webp, svg';

export const PAGES = [
  { id: 'home', name: 'Home' },
  { id: 'provide-information', name: 'Provide Information' },
  { id: 'donate', name: 'Donate' },
  { id: 'reward-up', name: 'Reward Up' },
  { id: 'about', name: 'About' },
  { id: 'wanted', name: 'Wanted' },
  { id: 'faqs', name: 'FAQs' },
  { id: 'support', name: 'Support' },
  { id: 'privacy', name: 'Privacy' },
  { id: 'terms', name: 'Terms' },
];
