import { useRecordContext } from 'react-admin';
import * as React from 'react';

export const CustomImageField = (props: any) => {
  const record = useRecordContext();
  const url = record.file_id ? record.src : record.url;

  return record?.url || record?.file_id ? (
    <img
      src={`${process.env.REACT_APP_API_URL}/${url}`}
      style={{ width: 130, height: 130, objectFit: 'contain' }}
    />
  ) : (
    <></>
  );
};
