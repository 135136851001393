import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

export const wrapperDataProvider = (apiUrl, httpClient) => {
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST: {
        const sortField =
          params.sort?.field === 'id' ? '_id' : params.sort?.field;

        const query = {
          sort: JSON.stringify({
            [sortField]: params.sort?.order == 'ASC' ? -1 : 1,
          }),
          skip: (params.pagination?.page - 1) * params.pagination?.perPage,
          limit: params.pagination?.perPage,
          query: JSON.stringify(params.filter),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ _id: params.ids }),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const query = {
          sort: JSON.stringify({
            [params.sort?.field]: params.pagination?.order == 'ASC' ? -1 : 1,
          }),
          skip: (params.pagination?.page - 1) * params.pagination?.perPage,
          limit: params.pagination?.perPage,
          query: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';
        options.data = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.data = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  const convertHTTPResponse = (response, type, resource, params) => {
    if (type === GET_LIST || type === GET_MANY || type === GET_MANY_REFERENCE) {
      return {
        data:
          response?.data?.length > 0
            ? response?.data.map((item) => {
                item.id = item?._id;
                delete item?._id;
                return item;
              })
            : [],
        total: parseInt(response?.headers['x-total-count'], 0),
      };
    }

    if (type === DELETE || type === DELETE_MANY) {
      return { data: params };
    }

    if (response?.data && response?.data?._id) {
      response.data.id = response?.data?._id;
      delete response?.data?._id;
    }
    return { data: response?.data };
  };

  return (type, resource, params) => {
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    const data = convertDataRequestToHTTP(type, resource, params);

    return httpClient(data?.url, data?.options).then((response) => {
      return convertHTTPResponse(response, type, resource, params);
    });
  };
};
