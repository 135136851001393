import {
  ArrayInput,
  FormTab,
  ImageField,
  ImageInput,
  required,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { crimesService } from '../../services/crimes';
import { getLocales } from '../../helpers/locales';
import {
  IMAGE_ALLOWED_EXTENSIONS,
  IMAGE_BAD_EXTENSION,
  MAX_IMAGE_ERROR,
  MAX_IMAGE_SIZE,
  requiredMessage,
} from '../../helpers/consts';
import { CustomImageField } from '../../components/custom-image-field';
import { validateImage } from '../../helpers/validate';
import { LinkPoster } from '../../components/link-poster';
import slugify from 'slugify';
import { slugifyOptions } from '../../helpers/slugify-options';

const CrimePoster = () => {
  const record = useRecordContext();

  if (
    record?.rawFile?.type &&
    !IMAGE_ALLOWED_EXTENSIONS.includes(record?.rawFile?.type)
  ) {
    return (
      <div style={{ width: 200, marginTop: -15 }}>
        <div className={'error-image'}>{IMAGE_BAD_EXTENSION}</div>
      </div>
    );
  }

  if (record?.rawFile?.size > MAX_IMAGE_SIZE) {
    return (
      <div style={{ width: 130, height: 120 }}>
        <img
          src={record.src}
          style={{ width: 120, height: 120, objectFit: 'contain' }}
          alt={'poster'}
        />
        <div className={'error-image'}>{MAX_IMAGE_ERROR}</div>
      </div>
    );
  }

  return <ImageField source={'src'} title="title" />;
};

export const CrimeForm = () => {
  let { id } = useParams();
  const redirect = useRedirect();

  const imageErrorRef = React.useRef<HTMLDivElement | null>(null);
  const [imageError, setImageError] = React.useState('');

  const [crime, setCrime] = React.useState<any>(null);
  const [defaultPoster, setDefaultPoster] = React.useState<any>(null);
  const [poster, setPoster] = React.useState<any | null>(null);
  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [locales, setLocales] = React.useState<string[]>([]);

  const onSubmit = async (data: any) => {
    if (data['poster']) delete data['poster'];

    const slug = data.slug ? slugify(data.slug, slugifyOptions) : null;

    if (!id) {
      await crimesService.createOne(
        { body: { ...data, slug }, poster },
        {
          success: () => redirect('/crimes'),
          failure: () => console.error('not created'),
        },
      );
    } else {
      await crimesService.updateOne(
        id,
        {
          body: {
            ...data,
            slug,
            oldPoster: crime?.poster,
          },
          poster: poster ?? defaultPoster,
        },
        {
          success: () => redirect('/crimes'),
          failure: () => console.error('not updated'),
        },
      );
    }
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      crimesService.getOne(id).then((res) => {
        setCrime({
          ...res?.data,
          poster: res?.data?.poster
            ? {
                ...res?.data?.poster,
                url: `${process.env.REACT_APP_API_URL}/${res?.data?.poster?.url}`,
              }
            : null,
        });
        setDefaultPoster(res?.data.poster);
      });
    }
  }, [id]);

  // TODO: add loader
  if (id && !crime) {
    return null;
  }

  return (
    <TabbedForm defaultValues={crime} onSubmit={onSubmit} shouldFocusError>
      <FormTab label={'General'}>
        <div ref={imageErrorRef}></div>
        <ImageInput
          source={'poster'}
          className={imageError && 'image-field--error'}
          defaultValue={crime?.poster}
          label="Poster"
          validate={(value) =>
            validateImage(value, (errorMessage) => {
              imageErrorRef.current?.scrollIntoView();
              setImageError(errorMessage);
            })
          }
          onChange={(data) => {
            setPoster(data);
            setDefaultPoster(data);
          }}
        >
          {crime?.poster?.url && !poster ? (
            <CustomImageField source={'url'} title="title" />
          ) : (
            <CrimePoster />
          )}
        </ImageInput>

        <TextInput fullWidth source="slug" label="Slug" />

        <TextInput
          fullWidth
          source="poster_author"
          label="Poster author"
          defaultValue={crime?.poster_author}
        />

        <ArrayInput
          label={'Links'}
          source="links"
          defaultValue={crime?.links || []}
        >
          <SimpleFormIterator>
            <ImageInput
              source="poster"
              label="Poster"
              validate={(value) =>
                validateImage(value, (errorMessage) => errorMessage)
              }
            >
              <LinkPoster />
            </ImageInput>

            {locales.map((locale) => (
              <TextInput
                multiline
                fullWidth
                key={locale}
                source={`title.${locale}`}
                label={`Title (${locale})`}
                validate={
                  locale === defaultLocale
                    ? required(requiredMessage)
                    : undefined
                }
              />
            ))}

            {locales.map((locale) => (
              <TextInput
                multiline
                fullWidth
                key={locale}
                source={`description.${locale}`}
                label={`Description (${locale})`}
                validate={
                  locale === defaultLocale
                    ? required(requiredMessage)
                    : undefined
                }
              />
            ))}

            {locales.map((locale) => (
              <TextInput
                multiline
                fullWidth
                key={locale}
                source={`link_name.${locale}`}
                label={`Link name (${locale})`}
                validate={
                  locale === defaultLocale
                    ? required(requiredMessage)
                    : undefined
                }
              />
            ))}

            {locales.map((locale) => (
              <TextInput
                multiline
                fullWidth
                key={locale}
                source={`link_description.${locale}`}
                label={`Link description (${locale})`}
                validate={
                  locale === defaultLocale
                    ? required(requiredMessage)
                    : undefined
                }
              />
            ))}

            <TextInput
              multiline
              fullWidth
              source={'link'}
              label={'Link'}
              validate={required(requiredMessage)}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      {locales.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`Name (${locale})`}
            source={`name.${locale}`}
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            defaultValue={crime?.name?.[locale] || ''}
          />

          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`Description (${locale})`}
            source={`description.${locale}`}
            defaultValue={crime?.description?.[locale] || ''}
          />

          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`SEO Title (${locale})`}
            source={`seo_title.${locale}`}
            defaultValue={crime?.seo_title?.[locale] || ''}
          />

          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`SEO Description (${locale})`}
            source={`seo_description.${locale}`}
            defaultValue={crime?.seo_description?.[locale] || ''}
          />

          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`SEO Keywords (${locale})`}
            source={`seo_keywords.${locale}`}
            defaultValue={crime?.seo_keywords?.[locale] || ''}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
};
