import * as React from 'react';
import { Edit, Create } from 'react-admin';

import { AccountForm } from './form';

export const AccountCreate = (props: any) => {
  return (
    <Create {...props} title={'Account Create'}>
      <AccountForm />
    </Create>
  );
};

export const AccountEdit = (props: any) => {
  return (
    <Edit {...props} title={'Account Edit'}>
      <AccountForm />
    </Edit>
  );
};
