import { useRecordContext } from 'react-admin';
import * as React from 'react';

interface ICustomImageFieldListProps {
  name: string;

  [key: string]: any;
}

export const CustomImageFieldList: React.FC<ICustomImageFieldListProps> = (
  props: any,
) => {
  const { name } = props;

  const record = useRecordContext();
  return record?.[name]?.url ? (
    <img
      src={`${process.env.REACT_APP_API_URL}/${record[name].url}`}
      style={{ width: 130, height: 130, objectFit: 'contain' }}
    />
  ) : (
    <></>
  );
};
