import {
  NumberInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { accountsService, IAccountPayload } from '../../services/accounts';

const AccountToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton label="Update" />
  </Toolbar>
);

export const AccountForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [account, setAccount] = React.useState<IAccountPayload | null>(null);

  const onSubmit = async (data: any) => {
    if (!id) {
      await accountsService.createOne(data, {
        success: () => redirect('/account'),
        failure: () => console.error('not created'),
      });
    } else {
      await accountsService.updateOne(id, data, {
        success: () => redirect('/account'),
        failure: () => console.error('not updated'),
      });
    }
  };

  React.useEffect(() => {
    if (id) {
      accountsService.getOne(id).then((res) => setAccount(res?.data));
    }
  }, [id]);

  if (id && !account) {
    // TODO: add loader
    return null;
  }

  return (
    <SimpleForm
      onSubmit={onSubmit}
      toolbar={<AccountToolbar />}
      shouldFocusError
    >
      <NumberInput
        name={'amount'}
        label={'Amount'}
        source={'amount'}
        defaultValue={account?.amount}
      />
    </SimpleForm>
  );
};
