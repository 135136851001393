import { IDoneFunctions } from '../interfaces';
import { instances } from '../core/axios';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface ILanguagePayload {
  name: string;
  code: string;
  is_default: boolean;
}

export const languageService = {
  async getAll(done?: IDoneFunctions) {
    try {
      const { data } = await instances.commonInstance.get(`languages?limit=0`);
      return data;
    } catch (e) {
      done?.failure();
    }
  },

  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`languages/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: ILanguagePayload, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.post('languages', payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`languages`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/languages`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(
    id: string,
    payload: ILanguagePayload,
    done?: IDoneFunctions,
  ) {
    try {
      await instances.commonInstance.patch(`languages/${id}`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`languages/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/languages`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
