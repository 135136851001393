import { IDoneFunctions } from '../interfaces';
import { instances } from '../core/axios';
import { filesService } from './files';
import { getLocales } from '../helpers/locales';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface IStaticPagePayload {
  name: string;
  title: Record<string, string>;
  information_list_title?: Record<string, string>;
  donate_list_title?: Record<string, string>;
  description: Record<string, string>;
  seo_title: Record<string, string>;
  seo_description: Record<string, string>;
  seo_keywords: Record<string, string>[];
  persons?: Record<string, any>[];
  founders?: Record<string, any>[];
  information_list?: Record<string, any>[];
  donate_list?: Record<string, any>[];
}

export interface IHomePersonAvatar {
  src: string;
  file_id: string;
  rawFile?: File;
}

export interface IHomePerson {
  avatar: IHomePersonAvatar;
  name: Record<string, string>;
  quote: Record<string, string>;
  date: string;
}

export interface IAboutFounder {
  avatar: IHomePersonAvatar;
  name: Record<string, string>;
  description: Record<string, string>;
}

export const getStaticPagePayload = async (data: any, mdData: any) => {
  const { locales } = await getLocales();

  const newObj: any = {};
  const keysArr = Object.keys(mdData).map((key) => key.split('.'));

  for (const key of keysArr) {
    newObj[key[0]] = {
      ...newObj.description,
      [key[1]]: mdData[key[0] + '.' + key[1]],
    };
  }

  const donateList = [];
  if (data?.donate_list?.length) {
    for (const elem of data.donate_list) {
      donateList.push({
        item: elem.item,
      });
    }
  }

  let donateListTitle = data?.donate_list_title || {};

  const informationList = [];
  if (data?.information_list?.length) {
    for (const elem of data.information_list) {
      informationList.push({
        item: elem.item,
      });
    }
  }

  let informationListTitle = data?.information_list_title || {};

  const newPersons: IHomePerson[] = [];
  if (data?.persons?.length) {
    for (const person of data.persons) {
      let avatar: any = person.avatar || {};

      if (avatar.rawFile) {
        const file = await filesService.createOne(person.avatar);
        avatar = file;
      }

      newPersons.push({
        name: person.name,
        quote: person.quote,
        date: person.date,
        avatar: {
          src: avatar.url || avatar.src,
          file_id: avatar._id || avatar.file_id,
        },
      });
    }
  }

  const newFounders: IAboutFounder[] = [];
  if (data?.founders?.length) {
    for (const founder of data.founders) {
      let avatar: any = founder.avatar || {};

      if (avatar.rawFile) {
        const file = await filesService.createOne(founder.avatar);
        avatar = file;
      }

      newFounders.push({
        name: founder.name,
        description: founder.description,
        avatar: {
          src: avatar.url || avatar.src,
          file_id: avatar._id || avatar.file_id,
        },
      });
    }
  }

  if (!newObj.description) {
    const emptyDescription: any = {};

    locales.forEach((locale) => {
      emptyDescription[locale] = '';
    });

    newObj['description'] = emptyDescription;
  }

  return {
    ...data,
    ...newObj,
    information_list_title: informationListTitle,
    donate_list_title: donateListTitle,
    information_list: informationList.length ? informationList : [],
    donate_list: donateList.length ? donateList : [],
    persons: newPersons.length ? newPersons : [],
    founders: newFounders.length ? newFounders : [],
  };
};

export const staticPageService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`static-pages/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: any, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.post(`static-pages`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`static-pages`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/static-pages`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: any, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.patch(`static-pages/${id}`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`static-pages/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/static-pages`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
