import { Datagrid, List, SearchInput, TextField } from 'react-admin';
import * as React from 'react';
import { DEFAULT_LOCALE } from '../../helpers/consts';

const dictionaryFilters = [
  <SearchInput
    alwaysOn
    source={`all_values`}
    placeholder={`Search by value`}
  />,
];

export const DictionariesList = (props: any) => {
  return (
    <List {...props} filters={dictionaryFilters}>
      <Datagrid rowClick="edit">
        <TextField label={'Field Key'} source={'field_key'} />

        <TextField label={'Value'} source={`value.${DEFAULT_LOCALE}`} />
      </Datagrid>
    </List>
  );
};
