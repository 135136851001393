import { requiredMessage } from '../../helpers/consts';
import {
  FormTab,
  required,
  SimpleForm,
  TabbedForm,
  TextInput,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  IPerpStatusPayload,
  perpStatusesService,
} from '../../services/perp-statuses';
import { getLocales } from '../../helpers/locales';

export const PerpStatusForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [locales, setLocales] = React.useState<string[]>([]);
  const [perpStatus, setPerpStatus] = React.useState<IPerpStatusPayload | null>(
    null,
  );

  const onSubmit = async (data: any) => {
    if (!id) {
      await perpStatusesService.createOne(data, {
        success: () => redirect('/perp-statuses'),
        failure: () => console.error('not created'),
      });
    } else {
      await perpStatusesService.updateOne(id, data, {
        success: () => redirect('/perp-statuses'),
        failure: () => console.error('not updated'),
      });
    }
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      perpStatusesService.getOne(id).then((res) => setPerpStatus(res?.data));
    }
  }, [id]);

  if (id && !perpStatus) {
    // TODO: add loader
    return null;
  }

  return (
    <TabbedForm onSubmit={onSubmit} shouldFocusError>
      {locales.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            key={locale}
            label={`Name (${locale})`}
            source={`name.${locale}`}
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            defaultValue={perpStatus?.name[locale] || ''}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
};
