import {
  Datagrid,
  List,
  ReferenceField,
  SearchInput,
  TextField,
  UrlField,
  Pagination,
  useListContext,
} from 'react-admin';
import * as React from 'react';
import Sortable from 'sortablejs';
import MenuIcon from '@mui/icons-material/Menu';

import { CustomImageFieldList } from '../../components/custom-image-field-list';
import { DEFAULT_LOCALE } from '../../helpers/consts';
import instances from '../../core/axios';

const perpsFilters = [
  <SearchInput alwaysOn source={`all_names`} placeholder={`Search by name`} />,
];

const MyPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 9999999]} {...props} />
);

const PerpsData = () => {
  const { data } = useListContext();

  const [updatedList, setUpdatedList] = React.useState<any[]>([]);

  React.useEffect(() => {
    setUpdatedList(data);
  }, [data]);

  React.useEffect(() => {
    const retries = 3;
    const millisecords = 1500;
    let attempts = 0;

    const retrySortable = () => {
      const el = document.querySelector('#sortable-list tbody');
      if (!el) {
        if (++attempts >= retries) {
          console.log(`cannot initialise sortable after ${retries} retries.`);
        } else {
          setTimeout(() => retrySortable(), millisecords);
        }
      } else {
        // @ts-ignore
        new Sortable(el, {
          handle: '.handle',
          delay: 0,
          draggable: 'tr',
          animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
          easing: 'cubic-bezier(1, 0, 0, 1)', // Easing for animation. Defaults to null. See https://easings.net/ for examples.
          // Element dragging ended
          onEnd: async (evt) => {
            setUpdatedList((prev) => {
              // @ts-ignore
              let newList = [...prev];
              // Remove dragged item
              const [reorderedItem] = newList.splice(evt.oldIndex as any, 1);
              // Add dropped item
              newList.splice(evt.newIndex as any, 0, reorderedItem);

              newList.forEach(
                async (elem, index) =>
                  await instances.commonInstance.patch(
                    `perps/update-index/${elem.id}`,
                    { index: +index },
                  ),
              );

              // Update State
              return newList;
            });
          },
        });
      }
    };
    retrySortable();
  }, []);

  return (
    <section id="sortable-list">
      <Datagrid rowClick="edit">
        <MenuIcon sx={{ cursor: 'pointer' }} className="handle" />

        <CustomImageFieldList
          sortable={false}
          name={'avatar'}
          label={'Avatar'}
          source={'avatar'}
        />

        <TextField
          label={'Name'}
          source={`name.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        {/*<TextField*/}
        {/*  label={'Other name'}*/}
        {/*  source={`other_name.${DEFAULT_LOCALE}`}*/}
        {/*  sortable={false}*/}
        {/*/>*/}

        <TextField
          label={'Country'}
          source={`country.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        <TextField
          label={'Position'}
          source={`position.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        <TextField
          label={'Incriminated'}
          source={`incriminated.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        {/*<TextField*/}
        {/*  label={'Address'}*/}
        {/*  source={`address.${DEFAULT_LOCALE}`}*/}
        {/*  sortable={false}*/}
        {/*/>*/}

        <TextField
          label={'Decision'}
          source={`decision.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        {/*<UrlField label={'Decision link'} source={'decision_link'} />*/}

        <ReferenceField source="crime_id" reference="crimes" sortable={false}>
          <TextField source={`name.${DEFAULT_LOCALE}`} />
        </ReferenceField>

        <ReferenceField
          source="perp_status_id"
          reference="perp-statuses"
          sortable={false}
        >
          <TextField source={`name.${DEFAULT_LOCALE}`} />
        </ReferenceField>

        <TextField
          label={'Remuneration amount'}
          source={'remuneration_amount'}
          sortable={false}
        />

        {/*<TextField label={'Date of birth'} source={'date_of_birth'} />*/}

        {/*<TextField*/}
        {/*  label={'EDPRU code'}*/}
        {/*  source={'edpru_code'}*/}
        {/*  sortable={false}*/}
        {/*/>*/}

        {/*<TextField label={'DRFO code'} source={'drfo_code'} sortable={false} />*/}

        {/*<TextField*/}
        {/*  label={'Russian Id'}*/}
        {/*  source={'russian_id'}*/}
        {/*  sortable={false}*/}
        {/*/>*/}
      </Datagrid>
    </section>
  );
};

export const PerpsList = (props: any) => {
  return (
    <List
      {...props}
      filters={perpsFilters}
      pagination={<MyPagination />}
      sort={{ field: 'index', order: -1 }}
    >
      <PerpsData />
    </List>
  );
};
