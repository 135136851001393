import * as React from "react";
import {
  Edit,
  Create
} from 'react-admin';

import {PerpStatusForm} from "./form";

export const PerpStatusCreate = (props: any) => {
  return (
    <Create {...props} title={'Perp Status Create'}>
      <PerpStatusForm/>
    </Create>
  );
}

export const PerpStatusEdit = (props: any) => {
  return (
    <Edit {...props} title={'Perp Status Edit'}>
      <PerpStatusForm/>
    </Edit>
  );
}