import {
  TextInput,
  SimpleForm,
  Login,
  useLogin,
  useNotify,
  PasswordInput,
  SaveButton, required,
} from "react-admin";
import * as React from "react";
import {requiredMessage} from "../helpers/consts";

export const LoginPage = () => {
  const login = useLogin();
  const notify = useNotify();

  const onSubmit = (data: any) => {
    const {email, password} = data
    login({email, password}).catch(() => notify("Invalid email or password"));
  };

  return (
    <Login>
      <SimpleForm onSubmit={onSubmit} toolbar={<></>}>
        <TextInput fullWidth source={'email'} validate={required(requiredMessage)}/>

        <PasswordInput fullWidth source={'password'} validate={required(requiredMessage)}/>

        <SaveButton
          alwaysEnable
          icon={<></>}
          style={{height: 40, width: 100, fontSize: 14}}
          label="Login"
        />
      </SimpleForm>
    </Login>
  )
}