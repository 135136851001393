import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

axios.defaults.withCredentials = true;

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
const authorization = `Bearer ${localStorage.getItem('access_token')}`;

export const instance = axios.create({
  baseURL,
  headers: {
    Authorization: authorization,
  },
});

export const instances = {
  commonInstance: axios.create({
    baseURL,
    headers: {
      Authorization: authorization,
    },
  }),

  fileInstance: axios.create({
    baseURL,
    headers: {
      Authorization: authorization,
      'Content-Type': 'multipart/form-data;',
    },
  }),
};

export const refreshAuth = () => {
  return instance
    .get('/auth/refresh')
    .then(({ data }) => {
      localStorage.setItem('access_token', data.access_token);

      return data;
    })
    .then(() => Promise.resolve())
    .catch((err) => Promise.reject());
};

Object.values(instances).forEach((instance) =>
  createAuthRefreshInterceptor(instance, refreshAuth, { statusCodes: [401] }),
);

export default instances;
