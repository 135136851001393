import {
  Datagrid,
  List,
  Pagination,
  TextField,
  useListContext,
} from 'react-admin';
import * as React from 'react';
import { DEFAULT_LOCALE } from '../../helpers/consts';
import Sortable from 'sortablejs';
import instances from '../../core/axios';
import MenuIcon from '@mui/icons-material/Menu';

const MyPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 9999999]} {...props} />
);

const FaqsData = () => {
  const { data } = useListContext();

  const [updatedList, setUpdatedList] = React.useState<any[]>([]);

  React.useEffect(() => {
    setUpdatedList(data);
  }, [data]);

  React.useEffect(() => {
    const retries = 3;
    const millisecords = 1500;
    let attempts = 0;

    const retrySortable = () => {
      const el = document.querySelector('#sortable-list tbody');
      if (!el) {
        if (++attempts >= retries) {
          console.log(`cannot initialise sortable after ${retries} retries.`);
        } else {
          setTimeout(() => retrySortable(), millisecords);
        }
      } else {
        // @ts-ignore
        new Sortable(el, {
          handle: '.handle',
          delay: 0,
          draggable: 'tr',
          animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
          easing: 'cubic-bezier(1, 0, 0, 1)', // Easing for animation. Defaults to null. See https://easings.net/ for examples.
          // Element dragging ended
          onEnd: async (evt) => {
            setUpdatedList((prev) => {
              // @ts-ignore
              let newList = [...prev];
              // Remove dragged item
              const [reorderedItem] = newList.splice(evt.oldIndex as any, 1);
              // Add dropped item
              newList.splice(evt.newIndex as any, 0, reorderedItem);

              newList.forEach(
                async (elem, index) =>
                  await instances.commonInstance.patch(
                    `faqs/update-index/${elem.id}`,
                    { index: +index },
                  ),
              );

              // Update State
              return newList;
            });
          },
        });
      }
    };
    retrySortable();
  }, []);

  return (
    <section id="sortable-list">
      <Datagrid rowClick="edit">
        <MenuIcon sx={{ cursor: 'pointer' }} className="handle" />

        <TextField
          label={'Question'}
          source={`question.${DEFAULT_LOCALE}`}
          sortable={false}
        />

        <TextField
          label={'Answer'}
          source={`answer.${DEFAULT_LOCALE}`}
          sortable={false}
        />
      </Datagrid>
    </section>
  );
};

export const FaqsList = (props: any) => {
  return (
    <List {...props} pagination={<MyPagination />}>
      <FaqsData />
    </List>
  );
};
