import { Datagrid, List, TextField } from 'react-admin';
import * as React from 'react';

export const AccountList = (props: any) => {
  return (
    <List {...props} title={'Account'} hasCreate={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label={'Amount'} source={'amount'} />
      </Datagrid>
    </List>
  );
};
