import {
  BooleanInput,
  DeleteButton,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { ILanguagePayload, languageService } from '../../services/languages';
import { DEFAULT_LOCALE, requiredMessage } from '../../helpers/consts';

const LanguageFormToolbar = (props: any) => {
  return (
    <Toolbar {...props} className={'language-toolbar'}>
      <SaveButton label="Save" alwaysEnable />

      <DeleteButton
        label="Delete"
        disabled={props.langCode === DEFAULT_LOCALE}
      />
    </Toolbar>
  );
};

export const LanguageForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [language, setLanguage] = React.useState<ILanguagePayload | null>(null);

  const onSubmit = async (data: any) => {
    if (!id) {
      await languageService.createOne(data, {
        success: () => redirect('/languages'),
        failure: () => console.error('not created'),
      });
    } else {
      await languageService.updateOne(id, data, {
        success: () => redirect('/languages'),
        failure: () => console.error('not updated'),
      });
    }
  };

  React.useEffect(() => {
    if (id) {
      languageService.getOne(id).then((res) => setLanguage(res?.data));
    }
  }, [id]);

  if (id && !language) {
    // TODO: add loader
    return null;
  }

  return (
    <SimpleForm
      onSubmit={onSubmit}
      toolbar={<LanguageFormToolbar langCode={language?.code} />}
      shouldFocusError
    >
      <TextInput
        multiline
        fullWidth
        label={'Name'}
        source={'name'}
        validate={required(requiredMessage)}
        defaultValue={language?.name || ''}
      />

      <TextInput
        multiline
        fullWidth
        label={'Code'}
        source={'code'}
        validate={required(requiredMessage)}
        defaultValue={language?.code || ''}
      />

      <BooleanInput
        fullWidth
        label={'Is Default'}
        source={'is_default'}
        defaultChecked={!!language?.is_default}
      />
    </SimpleForm>
  );
};
