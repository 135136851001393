import * as React from "react";
import {
  Edit,
  Create
} from 'react-admin';

import {DictionaryForm} from "./form";

export const DictionaryCreate = (props: any) => {
  return (
    <Create {...props} title={'Dictionary Create'}>
      <DictionaryForm/>
    </Create>
  );
}

export const DictionaryEdit = (props: any) => {
  return (
    <Edit {...props} title={'Dictionary Edit'}>
      <DictionaryForm/>
    </Edit>
  );
}