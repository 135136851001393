import { Datagrid, List, TextField } from 'react-admin';
import * as React from 'react';

import { DEFAULT_LOCALE } from '../../helpers/consts';

export const StaticPagesList = (props: any) => {
  return (
    <List {...props} title={'Static Pages'} hasCreate={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label={'Name'} source={'name'} />

        <TextField label={'Title'} source={`title.${DEFAULT_LOCALE}`} />

        <TextField
          label={'Description'}
          source={`description.${DEFAULT_LOCALE}`}
        />

        <TextField label={'SEO Title'} source={`seo_title.${DEFAULT_LOCALE}`} />

        <TextField
          label={'SEO Description'}
          source={`seo_description.${DEFAULT_LOCALE}`}
        />
      </Datagrid>
    </List>
  );
};
