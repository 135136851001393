import { IDoneFunctions } from '../interfaces';
import { instances } from '../core/axios';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface IUserPayload {
  _id?: string;
  full_name: string;
  password: string;
}

export const usersService = {
  async getOne(id: string, done?: IDoneFunctions) {
    const authorization = `Bearer ${localStorage.getItem('access_token')}`;
    try {
      return await instances.commonInstance.get(`admins/${id}`, {
        headers: { Authorization: authorization },
      });
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: IUserPayload, done?: IDoneFunctions) {
    try {
      const authorization = `Bearer ${localStorage.getItem('access_token')}`;
      await instances.commonInstance.post('users', payload, {
        headers: { Authorization: authorization },
      });
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`users`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/users`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: IUserPayload, done?: IDoneFunctions) {
    try {
      const authorization = `Bearer ${localStorage.getItem('access_token')}`;
      await instances.commonInstance.patch(`users/${id}`, payload, {
        headers: { Authorization: authorization },
      });
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`users/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/admins`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async deleteOne(id: string, done?: IDoneFunctions) {
    try {
      const authorization = `Bearer ${localStorage.getItem('access_token')}`;
      await instances.commonInstance.delete(`admins/${id}`, {
        headers: { Authorization: authorization },
      });
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .delete(`admins/${id}`, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/admins`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
