import { IDoneFunctions, ITranslatedField } from '../interfaces';
import { instance, instances, refreshAuth } from '../core/axios';
import { removeCookie, setCookie } from '../helpers/cookies';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface IPerpPayload {
  avatar: File | null;
  name: ITranslatedField;
  slug: string;
  other_name: ITranslatedField;
  country: Record<string, string>;
  position: Record<string, string>;
  incriminated: Record<string, string>;
  address: Record<string, string>;
  decision: Record<string, string>;
  decision_link: string;
  crime_id: string;
  perp_status_id: string;
  remuneration_amount: number;
  date_of_birth: string;
  edpru_code: string;
  drfo_code: string;
  russian_id: string;
  seo_title: Record<string, string>;
  seo_description: Record<string, string>;
  seo_keywords: any;
}

export const perpsService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`perps/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: any, done?: IDoneFunctions) {
    const formData = new FormData();

    formData.append('data', JSON.stringify(payload.body, null, 2));
    payload.avatar && formData.append('avatar', payload.avatar);

    try {
      await instances.fileInstance.post('perps', formData);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.fileInstance
              .post(`perps`, formData, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/perps`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(id: string, payload: any, done?: IDoneFunctions) {
    const formData = new FormData();

    if (payload.avatar instanceof File) {
      formData.append('data', JSON.stringify(payload.body, null, 2));
      payload.avatar && formData.append('avatar', payload.avatar);
    } else {
      formData.append(
        'data',
        JSON.stringify({ ...payload.body, avatar: payload.avatar }, null, 2),
      );
    }

    try {
      await instances.fileInstance.patch(`perps/${id}`, formData);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.fileInstance
              .patch(`perps/${id}`, formData, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                  'Content-Type': 'multipart/form-data;',
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/perps`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
