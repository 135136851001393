import {
  AdminProps,
  Datagrid,
  DateField,
  List,
  TextField,
  useRecordContext,
} from 'react-admin';
import * as React from 'react';

export const TransactionsList = (props: AdminProps) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField label={'Transaction id'} source={'transaction_id'} />

        <TextField label={'Pay date'} source={'pay_date'} />

        <TextField label={'Amount'} source={'amount'} />
      </Datagrid>
    </List>
  );
};
