import {
  DateInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextInput,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { IPerpPayload, perpsService } from '../../services/perps';
import {
  DEFAULT_LOCALE,
  IMAGE_ALLOWED_EXTENSIONS,
  IMAGE_BAD_EXTENSION,
  MAX_IMAGE_ERROR,
  MAX_IMAGE_SIZE,
  requiredMessage,
} from '../../helpers/consts';
import { getLocales } from '../../helpers/locales';
import { CustomImageField } from '../../components/custom-image-field';
import { validateImage, validateUrl } from '../../helpers/validate';
import slugify from 'slugify';
import { slugifyOptions } from '../../helpers/slugify-options';

const PerpAvatar = () => {
  const record = useRecordContext();

  if (
    record?.rawFile?.type &&
    !IMAGE_ALLOWED_EXTENSIONS.includes(record?.rawFile?.type)
  ) {
    return (
      <div style={{ width: 200, marginTop: -15 }}>
        <div className={'error-image'}>{IMAGE_BAD_EXTENSION}</div>
      </div>
    );
  }

  if (record?.rawFile?.size > MAX_IMAGE_SIZE) {
    return (
      <div style={{ width: 130, height: 120 }}>
        <img
          src={record.src}
          style={{ width: 120, height: 120, objectFit: 'contain' }}
          alt={'poster'}
        />
        <div className={'error-image'}>{MAX_IMAGE_ERROR}</div>
      </div>
    );
  }

  return <ImageField source={'src'} title="title" />;
};

export const PerpForm = () => {
  let { id } = useParams();
  const redirect = useRedirect();

  const imageErrorRef = React.useRef<HTMLDivElement | null>(null);
  const [perp, setPerp] = React.useState<IPerpPayload | null>(null);
  const [defaultAvatar, setDefaultAvatar] = React.useState<any>(null);
  const [defaultLocale, setDefaultLocale] = React.useState('');
  const [imageError, setImageError] = React.useState('');
  const [avatar, setAvatar] = React.useState<any | null>(null);
  const [locales, setLocales] = React.useState<string[]>([]);

  const onSubmit = async (data: any) => {
    if (data['avatar']) delete data['avatar'];

    let seoKeywords = data.seo_keywords || {};
    if (Array.isArray(perp?.seo_keywords)) {
      seoKeywords = {};
    }

    const slug = data.slug ? slugify(data.slug, slugifyOptions) : null;

    if (!id) {
      await perpsService.createOne(
        {
          body: {
            ...data,
            slug,
            seo_keywords: seoKeywords,
          },
          avatar,
        },
        {
          success: () => redirect('/perps'),
          failure: () => console.error('not created'),
        },
      );
    } else {
      await perpsService.updateOne(
        id,
        {
          body: {
            ...data,
            slug,
            seo_keywords: seoKeywords,
            oldAvatar: perp?.avatar,
          },
          avatar: avatar ?? defaultAvatar,
        },
        {
          success: () => redirect('/perps'),
          failure: () => console.error('not updated'),
        },
      );
    }
  };

  React.useEffect(() => {
    getLocales().then(({ locales, defaultLocale }) => {
      setLocales(locales);
      setDefaultLocale(defaultLocale);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      perpsService.getOne(id).then((res) => {
        setPerp(res?.data);
        setDefaultAvatar(res?.data?.avatar);
      });
    }
  }, [id]);

  // TODO: add loader
  if (id && !perp) {
    return null;
  }

  return (
    <TabbedForm onSubmit={onSubmit} shouldFocusError>
      <FormTab label={'General'}>
        <div ref={imageErrorRef}></div>
        <ImageInput
          source="avatar"
          label="Avatar"
          className={imageError && 'image-field--error'}
          validate={(value) =>
            validateImage(value, (errorMessage) => {
              imageErrorRef.current?.scrollIntoView();
              setImageError(errorMessage);
            })
          }
          onChange={(data) => {
            setImageError('');
            setAvatar(data);
            setDefaultAvatar(data);
          }}
        >
          {perp?.avatar && !avatar ? (
            <CustomImageField source="url" title="title" />
          ) : (
            <PerpAvatar />
          )}
        </ImageInput>

        <TextInput fullWidth source="slug" label="Slug" />

        <TextInput
          fullWidth
          isRequired
          validate={(val) => validateUrl(val)}
          source="decision_link"
          label="Decision link"
          defaultValue={perp?.decision_link}
        />

        <ReferenceInput
          source="crime_id"
          reference="crimes"
          defaultValue={perp?.crime_id}
        >
          <SelectInput
            fullWidth
            optionText="name.en"
            validate={required(requiredMessage)}
          />
        </ReferenceInput>

        <ReferenceInput
          fullWidth
          source="perp_status_id"
          reference="perp-statuses"
          defaultValue={perp?.perp_status_id}
        >
          <SelectInput
            fullWidth
            optionText="name.en"
            validate={required(requiredMessage)}
          />
        </ReferenceInput>

        <NumberInput
          fullWidth
          source="remuneration_amount"
          label="Remuneration amount"
          defaultValue={perp?.remuneration_amount}
        />

        <DateInput
          fullWidth
          source="date_of_birth"
          label="Date of birth"
          defaultValue={perp?.date_of_birth}
        />

        <TextInput
          fullWidth
          source="edpru_code"
          label="EDPRU code"
          defaultValue={perp?.edpru_code}
        />

        <TextInput
          fullWidth
          source="drfo_code"
          label="DRFO code"
          defaultValue={perp?.drfo_code}
        />

        <TextInput
          fullWidth
          source="russian_id"
          label="Russian id"
          defaultValue={perp?.russian_id}
        />
      </FormTab>

      {locales.map((locale) => (
        <FormTab label={locale}>
          <TextInput
            multiline
            fullWidth
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            label={`Name (${locale})`}
            name={`name.${locale}`}
            source={`name.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`Other name (${locale})`}
            name={`other_name.${locale}`}
            source={`other_name.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`Country (${locale})`}
            name={`country.${locale}`}
            source={`country.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`Position (${locale})`}
            name={`position.${locale}`}
            source={`position.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`Incriminated (${locale})`}
            name={`incriminated.${locale}`}
            source={`incriminated.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`Address (${locale})`}
            name={`address.${locale}`}
            source={`address.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            isRequired={locale === defaultLocale}
            validate={
              locale === defaultLocale ? required(requiredMessage) : undefined
            }
            label={`Decision (${locale})`}
            name={`decision.${locale}`}
            source={`decision.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`SEO Title (${locale})`}
            name={`seo_title.${locale}`}
            source={`seo_title.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`SEO Description (${locale})`}
            name={`seo_description.${locale}`}
            source={`seo_description.${locale}`}
          />

          <TextInput
            multiline
            fullWidth
            label={`SEO Keywords (${locale})`}
            name={`seo_keywords.${locale}`}
            source={`seo_keywords.${locale}`}
          />
        </FormTab>
      ))}
    </TabbedForm>
  );
};
