import { instance } from '../core/axios';

interface IRefreshData {
  access_token: string;
  refresh_token: string;
}

interface IRefreshWithRequest {
  onSuccess: (data: IRefreshData) => void;
  onFailure?: () => void;
}

export const refreshWithRequest = ({
  onSuccess,
  onFailure,
}: IRefreshWithRequest) => {
  return instance
    .get('/auth/refresh')
    .then(({ data }) => {
      localStorage.setItem('access_token', data.access_token);

      onSuccess(data);
    })
    .catch((err) => onFailure && onFailure());
};
