import * as React from "react";
import {
  Create,
  Edit,
} from 'react-admin';

import {StaticPageForm} from "./form";

export const StaticPageCreate = (props: any) => {
  return (
    <Create {...props} title={'Static Page Create'}>
      <StaticPageForm/>
    </Create>
  );
}

export const StaticPageEdit = (props: any) => {
  return (
    <Edit {...props} title={'Static Page Edit'}>
      <StaticPageForm/>
    </Edit>
  );
}