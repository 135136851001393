import { Datagrid, List, TextField } from 'react-admin';
import * as React from 'react';

export const AdminList = (props: any) => {
  return (
    <List {...props} title={'Admins'}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label={'Email'} source={'email'} />
      </Datagrid>
    </List>
  );
};
