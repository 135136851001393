import * as React from "react";
import {
  Create,
  Edit,
} from 'react-admin';
import {PerpForm} from "./form";

export const PerpCreate = () => {
  return (
    <Create title={'Perp Create'}>
      <PerpForm/>
    </Create>
  );
}

export const PerpEdit = (props: any) => {
  return (
    <Edit {...props} title={'Perp Edit'}>
      <PerpForm/>
    </Edit>
  );
}