import * as React from "react";
import {
  Edit,
  Create
} from 'react-admin';

import {FaqForm} from "./form";

export const FaqCreate = (props: any) => {
  return (
    <Create {...props} title={'Faq Create'}>
      <FaqForm/>
    </Create>
  );
}

export const FaqEdit = (props: any) => {
  return (
    <Edit {...props} title={'Faq Edit'}>
      <FaqForm/>
    </Edit>
  );
}