import {
  Button,
  BulkDeleteButton,
  PasswordInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useRedirect,
} from 'react-admin';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { IUserPayload, usersService } from '../../services/users';
import { requiredMessage } from '../../helpers/consts';
import instances from '../../core/axios';

const AdminToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton label="Update" />

    {props.hasDelete && (
      <BulkDeleteButton
        onClick={() => props.removeAdmin()}
        style={{ height: '100%', marginLeft: 'auto' }}
        label={'Delete'}
      />
    )}
  </Toolbar>
);

export const AdminForm = () => {
  const { id } = useParams();
  const redirect = useRedirect();

  const [user, setUser] = React.useState<IUserPayload | null>(null);
  const [hasDelete, setHasDelete] = React.useState<boolean>(false);

  const onSubmit = async (data: any) => {
    if (!id) {
      await usersService.createOne(data, {
        success: () => redirect('/admins'),
        failure: () => console.error('not created'),
      });
    } else {
      await usersService.updateOne(id, data, {
        success: () => redirect('/admins'),
        failure: () => console.error('not updated'),
      });
    }
  };

  const removeAdmin = () => {
    if (user?._id) {
      usersService.deleteOne(user._id, {
        success: () => redirect('/admins'),
        failure: () => console.error('not removed'),
      });
    }
  };

  React.useEffect(() => {
    instances.commonInstance.get('/admins').then(({ data }) => {
      setHasDelete(data.length > 1);
    });
  }, []);

  React.useEffect(() => {
    if (id) {
      usersService.getOne(id).then((res) => setUser(res?.data));
    }
  }, [id]);

  if (id && !user) {
    // TODO: add loader
    return null;
  }

  return (
    <SimpleForm
      onSubmit={onSubmit}
      toolbar={<AdminToolbar hasDelete={hasDelete} removeAdmin={removeAdmin} />}
      shouldFocusError
    >
      <TextInput
        fullWidth
        name={'email'}
        label={'Email'}
        source={'email'}
        validate={required(requiredMessage)}
      />

      <PasswordInput
        fullWidth
        name={'new_password'}
        label={'Password'}
        source={'new_password'}
        validate={required(requiredMessage)}
      />
    </SimpleForm>
  );
};
