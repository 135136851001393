import * as React from "react";
import {
  Create,
  Edit
} from 'react-admin';

import {CrimeForm} from "./form";

export const CrimeCreate = (props: any) => {
  return (
    <Create {...props} title={'Crime Create'}>
      <CrimeForm/>
    </Create>
  );
}

export const CrimeEdit = (props: any) => {
  return (
    <Edit {...props} title={'Crime Edit'}>
      <CrimeForm/>
    </Edit>
  );
}