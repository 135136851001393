import { IDoneFunctions } from '../interfaces';
import { instances } from '../core/axios';
import { refreshWithRequest } from '../helpers/refresh-with-request';

export interface ITransactionPayload {
  amount: number;
  transaction_id: string;
  pay_date: string;
}

export const transactionsService = {
  async getOne(id: string, done?: IDoneFunctions) {
    try {
      return await instances.commonInstance.get(`transactions/${id}`);
    } catch (e) {
      done?.failure();
    }
  },

  async createOne(payload: ITransactionPayload, done?: IDoneFunctions) {
    try {
      await instances.commonInstance.post('transactions', payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .post(`transactions`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/transactions`;
              });
          },
        });
      }
      done?.failure();
    }
  },

  async updateOne(
    id: string,
    payload: ITransactionPayload,
    done?: IDoneFunctions,
  ) {
    try {
      await instances.commonInstance.patch(`transactions/${id}`, payload);
      done?.success();
    } catch (e: any) {
      if (e.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return instances.commonInstance
              .patch(`transactions/${id}`, payload, {
                headers: {
                  Authorization: `Bearer ${data.access_token}`,
                },
              })
              .then(() => {
                document.location.href = `${document.location.origin}/#/transactions`;
              });
          },
        });
      }
      done?.failure();
    }
  },
};
