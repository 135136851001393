import { stringify } from 'query-string';
import axios from 'axios';
import { refreshWithRequest } from './helpers/refresh-with-request';

class HttpError extends Error {
  constructor(message, status, body = null) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.stack = new Error().stack;
  }
}

export const fetchJson = (url, options) => {
  const authorization = `Bearer ${localStorage.getItem('access_token')}`;

  const requestHeaders = {
    Authorization: authorization,
    'Content-Type': 'application/json',
  };

  return axios(url, {
    ...options,
    headers: requestHeaders,
    withCredentials: true,
  }).then(
    (response) => {
      if (response?.status < 200 || response?.status >= 300) {
        return Promise.reject({
          data: response?.data || response?.statusText,
          status: response?.status,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      if (error.response.status === 401) {
        refreshWithRequest({
          onSuccess: (data) => {
            return axios(url, {
              ...options,
              headers: {
                Authorization: `Bearer ${data.access_token}`,
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }).then(() => document.location.reload());
          },
          onFailure: () => {
            return Promise.reject({
              data: error.response?.data || error.response?.statusText,
              status: error.response?.status,
            });
          },
        });
      }

      if (error.response) {
        if (error.response?.status < 200 || error.response?.status >= 300) {
          return Promise.reject({
            data: error.response?.data || error.response?.statusText,
            status: error.response?.status,
          });
        }
      }
    },
  );
};

export const queryParameters = stringify;

const isValidObject = (value) => {
  if (!value) {
    return false;
  }

  const isArray = Array.isArray(value);
  const isBuffer = typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
  const isObject = Object.prototype.toString.call(value) === '[object Object]';
  const hasKeys = !!Object.keys(value).length;

  return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
  if (isValidObject(value)) {
    return Object.assign(
      {},
      ...Object.keys(value).map((key) =>
        flattenObject(value[key], path.concat([key])),
      ),
    );
  } else {
    return path.length ? { [path.join('.')]: value } : value;
  }
};
