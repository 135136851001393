import * as React from "react";
import {
  Create,
  Edit,
} from 'react-admin';

import {AdminForm} from "./form";

export const AdminCreate = (props: any) => {
  return (
    <Create {...props} title={'Admin Create'}>
      <AdminForm/>
    </Create>
  );
}

export const AdminEdit = (props: any) => {
  return (
    <Edit {...props} title={'Admin Edit'}>
      <AdminForm/>
    </Edit>
  );
}