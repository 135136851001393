import * as React from "react";
import {
  Edit,
  Create
} from 'react-admin';

import {TransactionForm} from "./form";

export const TransactionCreate = (props: any) => {
  return (
    <Create {...props} title={'Transaction Create'}>
      <TransactionForm/>
    </Create>
  );
}

export const TransactionEdit = (props: any) => {
  return (
    <Edit {...props} title={'Transaction Edit'}>
      <TransactionForm/>
    </Edit>
  );
}