import {
  IMAGE_ALLOWED_EXTENSIONS,
  IMAGE_BAD_EXTENSION,
  INVALID_URL_MESSAGE,
  MAX_IMAGE_ERROR,
  MAX_IMAGE_SIZE,
  requiredMessage,
} from './consts';

export const validateImage = (
  value: any,
  cb: (errorMessage: string) => void,
) => {
  if (value?.rawFile?.size > MAX_IMAGE_SIZE) {
    if (cb) {
      cb(MAX_IMAGE_ERROR);
      return ' ';
    } else {
      return MAX_IMAGE_ERROR;
    }
  }

  if (
    value?.rawFile &&
    !IMAGE_ALLOWED_EXTENSIONS.includes(value?.rawFile?.type)
  ) {
    if (cb) {
      cb(IMAGE_BAD_EXTENSION);
      return ' ';
    } else {
      return IMAGE_BAD_EXTENSION;
    }
  }
};

export const validateUrl = (
  value: string,
  cb?: (errorMessage: string) => void,
) => {
  const regex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  if (value.length === 0) {
    if (cb) {
      cb(requiredMessage);
      return ' ';
    } else {
      return requiredMessage;
    }
  }

  if (!regex.test(value)) {
    if (cb) {
      cb(INVALID_URL_MESSAGE);
      return ' ';
    } else {
      return INVALID_URL_MESSAGE;
    }
  }

  return undefined;
};
