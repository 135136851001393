import { AUTH_LOGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';

import { removeCookie, setCookie } from './helpers/cookies';
import { instance, refreshAuth } from './core/axios';

export default (type, params) => {
  const accessToken = localStorage.getItem('access_token');
  const authorization = `Bearer ${accessToken}`;

  if (type === AUTH_LOGIN) {
    const { email, password } = params;

    return instance
      .post('/auth/login', { email, password })
      .then((res) => {
        if (res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res.data;
      })
      .then((res) => {
        const { refresh_token, access_token } = res;

        // setCookie('refresh_token', refresh_token, 30);
        localStorage.setItem('access_token', access_token);
      });
  }

  if (type === AUTH_LOGOUT) {
    return instance.post('/auth/logout').then(() => {
      removeCookie('refresh_token');
      localStorage.removeItem('access_token');
      return Promise.resolve();
    });
  }

  if (type === AUTH_CHECK) {
    if (!accessToken) {
      return Promise.reject();
    }

    return instance
      .get('/auth/check', {
        headers: {
          Authorization: authorization,
        },
      })
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) =>
        refreshAuth()
          .then(() => Promise.resolve())
          .catch(() => {
            removeCookie('refresh_token');
            localStorage.removeItem('access_token');
            return Promise.reject();
          }),
      );
  }

  if (type === AUTH_ERROR) {
    const status = params.status;

    if (status === 401) {
      refreshAuth()
        .then((data) => {
          // setCookie('refresh_token', data.refresh_token, 30);
          localStorage.setItem('access_token', data.access_token);

          return Promise.resolve();
        })
        .catch(() => {
          removeCookie('refresh_token');
          localStorage.removeItem('access_token');
          return Promise.reject();
        });
    }

    return Promise.resolve();
  }
  return Promise.resolve();
};
