import { ILanguagePayload, languageService } from '../services/languages';
import { DEFAULT_LOCALE } from './consts';

export const getLocales = async () => {
  const languages: ILanguagePayload[] = await languageService.getAll();

  const defaultLocale =
    languages.find((lang) => lang.is_default)?.code || DEFAULT_LOCALE;
  const locales = languages.map(({ code }) => code);

  return {
    locales: [...locales],
    defaultLocale,
  };
};
