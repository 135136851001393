import * as React from "react";
import {
  Edit,
  Create
} from 'react-admin';

import {LanguageForm} from "./form";

export const LanguageCreate = (props: any) => {
  return (
    <Create {...props} title={'Language Create'}>
      <LanguageForm/>
    </Create>
  );
}

export const LanguageEdit = (props: any) => {
  return (
    <Edit {...props} title={'Language Edit'}>
      <LanguageForm/>
    </Edit>
  );
}